<template>
    <div class="city-all">
        <van-search
            v-model="searchCity"
            @input="filterHandle"
            shape="round"
            background="#EBF1FF"
            placeholder="请输入搜索关键词">
            <template #left class="back">
                <img @click="handleBack" src="@/assets/close.png" alt="back">
            </template>
        </van-search>
        <div class="now-address">
            <p>Current location / recent visit</p>
            <span class="now-city" @click="changeNowCity(nowAddress)">
                <img src="@/assets/cart/dingwei@2x.png" alt="">
                {{nowAddress.ename}}
            </span>
            <span class="now-city" @click="changeNowCity(myAddress)" v-if="myAddress">
                {{myAddress.ename}}
            </span>
        </div>
        <van-tabs
            @change="changeCountry"
            type="card"
            title-active-color="#fff"
            title-inactive-color="#666"
            swipe-threshold="2"
            v-model="countryId"
            >
            <van-tab v-for="item,index in data" :key='index' :title="item.ename"></van-tab>
        </van-tabs>
        <van-index-bar class="city-list" sticky :sticky-offset-top="54" :index-list="indexList">
            <template v-for="(item, index) in cityData">
                <van-index-anchor :index="item.abbreviation" :key="index"/>
                <template v-for="it in item.children">
                    <van-cell :title="it.ename" :key="it.id" @click="changeCity(index,it.ename)" />
                </template>
            </template>
        </van-index-bar>
    </div>
</template>

<style scoped lang="less">
.city-all{width:100%;height:100%;overflow-y: auto;background-color: #F5FAFF;
    .van-search{position: sticky;top:0;z-index: 999;
        img{
            width: 5.86667vw;
            height: 5.86667vw;
            margin-right:10px
        }
        .van-search__content{background-color: #fff;}
    }
    .now-address{width:100%;height:100px;padding:20px 16px 0 16px;
        p{
            font-size: 14px;
            
            font-weight: 400;
            color: #666666;
            display: block;
        }
        .now-city{
            display: inline-block;
            padding:10px 20px;
            height: 40px;
            background: #FFFFFF;
            box-shadow: 0px 5px 10px 0px rgba(217, 217, 217, 0.1);
            border-radius: 4px;
            font-size: 14px;
            
            font-weight: 500;
            color: #333333;
            text-align: center;
            margin-top:15px;
            margin-right:15px;
            img{width:16px;height:16px;vertical-align: -2px;}
        }
    }
    /deep/.van-index-bar__sidebar {
        height: 0;
        overflow: hidden;
        // height: 350px;
        // width: 40px;
        // overflow-x: hidden;
        // overflow-y: scroll;
        // overscroll-behavior-y: contain;
        // .van-index-bar__index {
        //     padding: 0;
        //     width: 20px;
        // }
    }
    /deep/ .van-tabs {
        margin-top: 10px;
        margin-bottom: 10px;
        padding-top: 5px;
        border-top: 2px solid #f2f2f2;
        .van-tabs__nav--card {
            background: transparent;
            border: none;
            margin: 0;
            padding: 0 12px;
            .van-tab {
                background: #fff;
                border: none;
                border-radius: 15px;
                margin-right: 8px;
            }
            .van-tab--active {
                background: #ee0a24;
            }
        }
    }
}
</style>

<script>
import '@/untils/js/geocoder.js'
import { getCityList } from '@/api/zh/index.js'
export default {
    data() {
        return {
            searchCity: '',
            nowAddress: {ename:'Unable to locate'},
            myAddress: '',
            countryId: '',
            data: [],
            cityData: [],
            tempCity: [],
            indexList: [],
            tempIndex: [],
            allCityList: [],
            filterTimer: null
        }
    },
    methods: {
        handleBack(){
            this.$router.go(-1)
        },
        getCityListHandle() {
            // 获取城市地址
            this.$store.state.load = true
            getCityList().then(res => {
                if(res) {
                    this.data = res.data
                    this.cityData = res.data[0].children
                    this.tempCity = res.data[0].children
                    this.cityData.forEach(el => {
                        this.indexList.push(el.abbreviation)
                        el.children.forEach(ll => {
                            this.allCityList.push(ll)
                        })
                    })
                    this.tempIndex = this.indexList
                }
            }).finally(() => {
                this.$store.state.load = false
            })
        },
        changeCountry() {
            this.indexList = []
            this.allCityList = []
            this.cityData = this.data[this.countryId].children
            this.tempCity = this.data[this.countryId].children
            this.cityData.forEach(el => {
                this.indexList.push(el.abbreviation)
                el.children.forEach(ll => {
                    this.allCityList.push(ll)
                })
            })
            this.tempIndex = this.indexList
        },
        changeCity(index, name) {
            // 切换地址
            let city = {
                ename: name,
                pname: this.cityData[index].ename
            }
            sessionStorage.setItem('geo_address', JSON.stringify(city))
            this.$notify({
                message: 'Address switch succeeded',
                type: 'success'
            })
            this.$router.back()
        },
        changeNowCity(name) {
            // 切换地址
            if(name.ename == 'Unable to locate') {
                this.$notify({
                    message: 'Unable to locate, please select the address manually',
                    type: 'warning'
                })
                return false
            }
            sessionStorage.setItem('geo_address', JSON.stringify(name))
            this.$notify({
                message: '地址切换成功',
                type: 'success'
            })
            this.$router.back()
        },
        filterHandle() {
            // 节流
            if(this.searchCity == '') {
                // 恢复数据列表
                this.cityData = this.tempCity
                this.indexList = this.tempIndex
            } else {
                // 筛选，显示数据
                clearTimeout(this.filterTimer)
                this.filterTimer = setTimeout(() => {
                    this.indexList = []
                    let data = this.allCityList.filter(it => it.ename.indexOf(this.searchCity) != -1)
                    this.cityData = [{children: data, abbreviation: ''}]
                    clearTimeout(this.filterTimer)
                }, 200)
            }
        }
    },
    mounted() {
        if(sessionStorage.getItem('geo_address')) {
            this.myAddress = JSON.parse(sessionStorage.getItem('geo_address'))
        }
        this.getCityListHandle()
        let timer = setInterval(() => {
            if(this.$store.state.city != null) {
                let city = this.$store.state.city.short_name
                if(city) {
                    this.nowAddress = city
                }
                clearInterval(timer)
            }
        }, 500)
    },
    // created() {
    //     if (this.$store.state.isMiniProgram) {
    //         window.wx.miniProgram.navigateTo({ url: "/pages/map/map" })
    //     }
    // }
}
</script>