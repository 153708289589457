/* eslint-disable */
import Store from '@/store'
// import { Notify } from 'vant'

function loadJs(url, callback) {
    var script = document.createElement('script');
    script.type='text/javascript';
    script.async='async';
    script.src=url;
    document.body.appendChild(script);
    if(script.readyState){   //IE
        script.onreadystatechange=function(){
            if(script.readyState=='complete'||script.readyState=='loaded'){
                script.onreadystatechange=null;
                callback();
            }
        }
    }else{//非IE
        script.onload=function(){callback();}
    }
}

loadJs('https://maps.googleapis.com/maps/api/js?key=AIzaSyB-5PBSLXVRJKf1gGIxiRo4ZuiFx7GOa3s&language=en&sensor=false', initialize)

var geocoder;
//Get the latitude and the longitude;
function successFunction(position) {
    var lat = position.coords.latitude;
    var lng = position.coords.longitude;
    codeLatLng(lat, lng)
}

function errorFunction(){
    // Notify({ type: 'warning', message: 'Geocoder failed' })
}

function initialize() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
    }

    geocoder = new google.maps.Geocoder();
}

function codeLatLng(lat, lng) {
    var latlng = new google.maps.LatLng(lat, lng);
    geocoder.geocode({'latLng': latlng}, function(results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
            console.log(results)
            let city = {}
            if (results) {
                for(var j = 0; j < results.length; j++) {
                    for (var i=0; i<results[j].address_components.length; i++) {
                        for (var b=0;b<results[j].address_components[i].types.length;b++) {
                            if (results[j].address_components[i].types[b] == "locality") {
                                city.ename= results[j].address_components[i].short_name;
                                city.pname= results[j].address_components[i+1].long_name;
                                break;
                            }
                        }
                    }
                }
                
                Store.state.city = city
            } else {
                // Notify({ type: 'warning', message: 'No results found' })
            }
        } else {
            // Notify({ type: 'warning', message: "Geocoder failed due to: " + status })
        }
    })
}
